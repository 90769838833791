/* istanbul ignore file -- @preserve */

import { datadogRum } from '@datadog/browser-rum';
import { initializeDatadogLinkFactory } from './initializeDatadogLink';
import { config } from './config';

function enableDatadog() {
  return config.isValid();
}

export function initializeDatadog() {
  if (!enableDatadog()) return;

  // Documentation:
  // https://docs.datadoghq.com/real_user_monitoring/connect_rum_and_traces

  datadogRum.init({
    applicationId: config.getApplicationId(),
    clientToken: config.getClientToken(),
    site: 'datadoghq.com',
    service: config.getServiceName(),
    env: config.getEnvironment(),
    version: config.getVersion(),
    sessionSampleRate: config.getRumSamplingRate(),
    sessionReplaySampleRate: 0,
    trackUserInteractions: false,
    trackResources: false,
    trackLongTasks: false,
    defaultPrivacyLevel: 'mask-user-input',
    allowedTracingUrls: config.tracingUrls(),
  });

  datadogRum.startSessionReplayRecording();
}

export const initializeDatadogLink = initializeDatadogLinkFactory.create(
  enableDatadog(),
);
