/* istanbul ignore file -- @preserve */

import { ApolloLink, FetchResult } from '@apollo/client';
import get from 'lodash/get';
import { datadogRum } from '@datadog/browser-rum';

import removeBlankProperties from 'utils/removeBlankProperties';

export function datadogUserTracker(props: FetchResult) {
  const data = get(props, 'data');

  if (data?.hasOwnProperty('currentUserProfile')) {
    const { user } = data.currentUserProfile;
    if (user) {
      const { uuid, name, email } = user;

      if (uuid && name && email) {
        const {
          role,
          firstName,
          lastName,
          sentinelTeam,
          lastKnownTimezone,
          lastKnownTimeOffset,
          superuser,
        } = user;

        const visitor = removeBlankProperties({
          id: uuid,
          name,
          email,
          firstName,
          lastName,
          role: get(role, 'name'),
          superuser,
          teamID: get(sentinelTeam, 'id'),
          teamName: get(sentinelTeam, 'name'),
          lastKnownTimezone,
          lastKnownTimeOffset,
        });

        datadogRum.setUser(visitor);
      }
    }
  }
}

const create = (isDatadogEnabled: boolean) => {
  return new ApolloLink((operation, forward) =>
    isDatadogEnabled
      ? forward(operation).map((props: FetchResult) => {
          datadogUserTracker(props);
          return props;
        })
      : forward(operation),
  );
};

export const initializeDatadogLinkFactory = { create };
