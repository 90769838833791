import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import { useMutation } from '@apollo/client';

import spacing from '@decisiv/design-tokens/lib/spacing';

import Grid from '@decisiv/ui-components/lib/components/Grid';
import Button from '@decisiv/ui-components/lib/components/Button';

import { t } from '@lingui/macro';

import Loading from 'components/Loading';
import groupNotifications from './groupNotifications';
import NotificationGroup from './NotificationGroup';
import useNotificationsQuery from './hooks/useNotificationsQuery';

import updateUnseenNotificationsMutation from './updateUnseenNotifications.graphql';

const Container = styled(Grid.Container)`
  max-height: ${rem(spacing.base * 40)};
  overflow-x: hidden;
  overflow-y: auto;
`;

interface GroupedNotificationsProps {
  handleOpenModal: () => void;
  setModalData: () => void;
}

const INITIAL_PAGES_TO_LOAD = 4;

function GroupedNotifications({
  handleOpenModal,
  setModalData,
}: GroupedNotificationsProps) {
  const [updateNotifications] = useMutation(updateUnseenNotificationsMutation);

  const { data, loading, fetchMore } = useNotificationsQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      page: 1,
    },
    onCompleted: async ({ notifications: { collection, pagination } }) => {
      const unseenNotifications = collection.filter(({ seen }) => !seen);

      if (!isEmpty(unseenNotifications)) {
        const ids = unseenNotifications.map(({ id }) => id);

        await updateNotifications({
          variables: { ids, attributes: { seen: true } },
        });
      }

      const { page: currentPage, totalPages } = pagination;

      if (currentPage < totalPages && currentPage < INITIAL_PAGES_TO_LOAD)
        await fetchMore({ variables: { page: currentPage + 1 } });
    },
  });

  const allNotifications = data?.notifications?.collection;

  if (!allNotifications && loading) return <Loading />;

  const groupedNotifications = groupNotifications(allNotifications);

  const currentPage = data?.notifications?.pagination.page ?? 1;
  const totalPages = data?.notifications?.pagination.totalPages ?? 1;

  const hasMore = currentPage < totalPages;

  const handleLoadMore = () => {
    fetchMore({ variables: { page: currentPage + 1 } });
  };

  return (
    <Container>
      {groupedNotifications.map(([date, notifications]) => (
        <NotificationGroup
          date={date}
          handleOpenModal={handleOpenModal}
          notifications={notifications}
          setModalData={setModalData}
        />
      ))}
      {hasMore && (
        <Grid.Row>
          <Grid.Column marginLeft={5.8} paddingBottom={2} paddingTop={1}>
            <Button
              kind="secondary"
              size="small"
              text={t`Load More`}
              onClick={handleLoadMore}
              loading={loading}
            />
          </Grid.Column>
        </Grid.Row>
      )}
    </Container>
  );
}

export default GroupedNotifications;
