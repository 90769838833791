import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import UserArrowRight from '@decisiv/iconix/lib/components/UserArrowRight';

import Modal from '@decisiv/ui-components/lib/components/Modal';
import { P } from '@decisiv/ui-components/lib/components/Typography';

import { t, Trans } from '@lingui/macro';

import useSentinelTeam from 'hooks/useSentinelTeam';

function ChangeTeamConfirmationModal({ visible, notification, onClose }) {
  const navigate = useNavigate();
  const currentSentinelTeam = useSentinelTeam();

  if (!notification) return null;

  const {
    sentinelTeam,
    source: { id },
  } = notification;

  const handleSubmit = async () => {
    // errorLink handles team changing, so we only need to navigate to appointment page
    navigate(`/service_events/${id}`);
    onClose();
  };
  const currentGroupName = currentSentinelTeam?.name;
  const nameOfNewSentinelTeam = sentinelTeam.name;

  return (
    <Modal
      actions={[
        { text: t`Continue`, onClick: handleSubmit },
        { text: t`Stay`, onClick: onClose },
      ]}
      color="information"
      icon={UserArrowRight}
      onClose={onClose}
      title={t`Continue to ${nameOfNewSentinelTeam}?`}
      visible={visible}
      zIndex={100}
    >
      <P shade={1}>
        {currentGroupName ? (
          <Trans>
            You are currently viewing team{' '}
            <P as="span" weight="semibold">
              {currentGroupName}
            </P>
            .
          </Trans>
        ) : (
          <Trans>
            You are currently viewing{' '}
            <P as="span" weight="semibold">
              All Teams
            </P>
            .
          </Trans>
        )}{' '}
        <Trans>
          To view appointment{' '}
          <P as="span" weight="semibold">
            {id}
          </P>
          , you will be taken to{' '}
          <P as="span" weight="semibold">
            {nameOfNewSentinelTeam}
          </P>
          .
        </Trans>
      </P>
    </Modal>
  );
}

ChangeTeamConfirmationModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  notification: PropTypes.shape({
    sentinelTeam: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string,
    }),
    source: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
  visible: PropTypes.bool.isRequired,
};

ChangeTeamConfirmationModal.defaultProps = {
  notification: null,
};

export default ChangeTeamConfirmationModal;
