/* istanbul ignore file -- @preserve */

/*
 * Configuration for Datadog, based on environment variables.
 *
 * Expected variables:
 *  * VITE_DATADOG_APPLICATION_ID
 *  * VITE_DATADOG_CLIENT_TOKEN
 *  * VITE_DATADOG_SERVICE
 *  * VITE_DATADOG_ENV
 *  * VITE_VERSION
 *  * VITE_BFF_API_URL
 */

const DEFAULT_RUM_SAMPLING_VALUE = 50;
const DEFAULT_SERVICE_NAME = 'managed-care-ui';

function getApplicationId() {
  return import.meta.env.VITE_DATADOG_APPLICATION_ID;
}

function getClientToken() {
  return import.meta.env.VITE_DATADOG_CLIENT_TOKEN;
}

function getServiceName() {
  return import.meta.env.VITE_DATADOG_SERVICE || DEFAULT_SERVICE_NAME;
}

function getRumSamplingRate() {
  const envValue = import.meta.env.VITE_DATADOG_RUM_SAMPLING_RATE;
  const samplingRate = parseInt(envValue || '', 10);
  return Number.isInteger(samplingRate)
    ? samplingRate
    : DEFAULT_RUM_SAMPLING_VALUE;
}

function getEnvironment() {
  return import.meta.env.VITE_DATADOG_ENV;
}

function getVersion() {
  return import.meta.env.VITE_VERSION;
}

function isValid() {
  return !!(getApplicationId() && getClientToken() && getVersion());
}

function tracingUrls() {
  return [import.meta.env.VITE_BFF_API_URL];
}

export const config = {
  getApplicationId,
  getClientToken,
  getServiceName,
  getRumSamplingRate,
  getEnvironment,
  getVersion,
  isValid,
  tracingUrls,
};
